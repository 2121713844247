import ContactUs from "@/components/ContactUs";
import SEO from "@/components/SEO";
import { graphql, PageProps, StaticQuery } from "gatsby";
import React, { FC, Fragment } from "react";

const ContactUsPage: FC<PageProps> = () => (
  <StaticQuery
    query={graphql`
      query ContactUsPage {
        contentfulContactUsPage {
          #     SEO
          seo {
            documentTitle
            metaTitle
            metaUrl
            metaAuthor
            description {
              description
            }
            metaKeywords
          }

          #     Footer content
          footerContent {
            content {
              raw
            }
          }
        }
      }
    `}
    render={({
      contentfulContactUsPage: {
        seo: {
          documentTitle,
          metaTitle,
          metaUrl,
          description: { description },
          metaKeywords,
          metaAuthor,
        },
        footerContent,
      },
    }) => {
      return (
        <Fragment>
          <SEO
            contentfulSeo={{
              documentTitle,
              metaTitle,
              metaDescription: description,
              metaKeywords,
              metaUrl,
              metaAuthor: metaAuthor,
            }}
          />
          <ContactUs footerContent={footerContent} />
        </Fragment>
      );
    }}
  />
);

export default ContactUsPage;
