import { Widget } from "@typeform/embed-react";
import React from "react";
import Layout from "../Layout";
import { BreadCrumb } from "../Shared/BreadCrumb";

export default function ContactUs({ footerContent }: { footerContent: string }): JSX.Element {
  const typeFormRef = React.useRef<HTMLButtonElement>(null);

  return (
    <Layout contentfulFooterContentForPages={footerContent}>
      <BreadCrumb
        routingPaths={[{ path: "/", routeName: "Home" }]}
        activePath={{ routeName: "Contact Us" }}
        title="Contact Us"
      />

      <Widget
        id="br6PI8Zy"
        className="mt-4"
        style={{ border: "0px", minHeight: "515px", height: "515px" }}
        onSubmit={() => {
          typeFormRef.current?.click();
        }}
      />

      <section className="section section-md pb-0 pt-5">
        <div className="container">
          <div className="row row-50">
            <div className="col-xl-5">
              <div className="inset-1">
                <h2>Our Locations</h2>
                <div className="row row-50 mb-2">
                  <div className="col-sm-6 col-xl-12">
                    <p className="heading-7">Doha, Qatar</p>
                    <ul className="list list-style-1">
                      <li className="unit">
                        <span className="unit-left icon icon-sm text-primary mdi mdi-map-marker" />
                        <div className="unit-body">
                          <a href="/">Oryx Business Center, Westbay, Doha</a>
                        </div>
                      </li>

                      <li className="unit">
                        <span className="unit-left icon icon-sm text-primary mdi mdi-cellphone" />
                        <div className="unit-body">
                          <a href="tel:+91 8069647545" id="contact-us-phone-qatar">
                            +91 8069647545
                          </a>
                        </div>
                      </li>
                      <li className="unit">
                        <span className="unit-left icon icon-sm text-primary mdi mdi-email-outline" />
                        <div className="unit-body">
                          <a
                            className="text-primary"
                            href="mailto:info@zartek.qa"
                            id="contact-us-email-qatar"
                          >
                            info@zartek.qa
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-6 col-xl-12">
                    <p className="heading-7">Kochi, India</p>
                    <ul className="list list-style-1">
                      <li className="unit">
                        <span className="unit-left icon icon-sm text-primary mdi mdi-map-marker" />
                        <div className="unit-body">
                          <a href="/">Kerala Technology Innovation Zone, Kochi, Kerala</a>
                        </div>
                      </li>
                      <li className="unit">
                        <span className="unit-left icon icon-sm text-primary mdi mdi-cellphone" />
                        <div className="unit-body">
                          <a href="tel:+91 8069647545" id="contact-us-phone-india">
                            +91 8069647545
                          </a>
                        </div>
                      </li>
                      <li className="unit">
                        <span className="unit-left icon icon-sm text-primary mdi mdi-email-outline" />
                        <div className="unit-body">
                          <a
                            className="text-primary"
                            href="mailto:info@zartek.in"
                            id="contact-us-email-india"
                          >
                            info@zartek.in
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
